// components/LanguageSwitcher.js
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import { getLabel, getReactSelectAriaLabel } from '../util/FormatUtil';
import { useEffect, useState } from 'react';

const LanguageSwitcher = ({ onChange }) => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en");

    const options = [
        { label: 'English', value: 'en' },
        { label: 'Espa\u00F1ol', value: 'es' },
    ];

    const selectOptions = options.map(option => ({
        label: option.label,
        value: option.value,
    }));

    useEffect(() => {
        const cookieValue = document.cookie
            .split('; ')
            .find((row) => row.startsWith('i18next='))?.split('=')[1];
            document.documentElement.setAttribute('lang', cookieValue || 'en');
    })

    const handleChange = selectedOption => {
        const selectedLanguage = selectedOption.value;
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        document.documentElement.setAttribute('lang', selectedLanguage);
        onChange(selectedLanguage);
    };

    return (
        <Select
            aria-label={getReactSelectAriaLabel("Language", getLabel(language, selectOptions, t), t)}
            className={"state_select"}
            options={selectOptions}
            onChange={handleChange}
            defaultValue={options.find(option => option.value === i18n.language)}
        />
    );
};

export default LanguageSwitcher;
