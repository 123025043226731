import React from "react";
import SystemAPI from "../network/SystemAPI";
import Overlay from "./Overlay";
import { withTranslation, Trans } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

interface HomeState {
    logoURL: string
    productName: string
    buttonColorHexValue: string
    showLoading: boolean
    selectedLanguage
}

class Home extends React.Component<{ t }, HomeState> {
    constructor(props) {
        super(props);
        this.state = {
            logoURL: '',
            productName: '',
            showLoading: false,
            buttonColorHexValue: '',
            selectedLanguage: 'en'
        };
    }

    componentDidMount(): void {

        this.setState({ showLoading: true }, () => {
            document.title = 'Patient Home Page';
            document.body.style.backgroundColor = 'black';

            let currentURL = window.location.href;

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let backgroundImageURL = data.ProductBackgroundImageURL;
                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
                this.setState({ logoURL: data.ProductLogoURL, productName: data.Name, buttonColorHexValue: data.ProductButtonColorHexValue, showLoading: false });
            })
        })

    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading} />
            <main id="main-content" tabIndex={-1} aria-label="Patient Home Page">
                <div className={this.state.showLoading ? "d-none" : "container min-vh-100 "}>
                    <div className="row responsive-p mt-5">
                        <div className={"col-12 pb-4"}>
                            <h1 className={"text-center verlag-light"}>
                                <img src={this.state.logoURL}
                                    className={"homepage-logo"}
                                    alt={"Oklahoma State Department of Health Immunization Service"} />
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 col-md-4 col-lg-5"></div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <LanguageSwitcher onChange={(language) => this.setState({ selectedLanguage: language })} />
                        </div>
                        <div className="col-3 col-md-4 col-lg-5"></div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-center text-dark mt-3">
                                <Trans
                                    i18nKey="welcomeMessage"
                                    values={{ productName: this.state.productName }}
                                    components={{ bold: <strong /> }}
                                />
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 d-flex flex-column justify-content-center align-content-center mt-3">
                            <a href={"/login"} className=" mx-auto btn btn-lg text-light" style={{ backgroundColor: this.state.buttonColorHexValue }}>
                                {this.props.t("logInButtonText")}
                            </a>
                            <a href={"/register"} className="mx-auto my-4 text-dark" style={{ fontSize: "18px" }}>
                                {this.props.t("registerMessage")}
                            </a>
                        </div>
                    </div>
                </div>

            </main>

        </React.Fragment>)
    }
}
export default withTranslation()(Home);