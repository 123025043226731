import React, { Component } from 'react'
import { sweetalert } from "../../App";
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import Overlay from "../Overlay";
import { hideModal, buildRow, getLabel, getReactSelectAriaLabel } from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Select, { createFilter } from "react-select";
import PhoneInput from 'react-phone-number-input';
import { withTranslation } from 'react-i18next';


interface SchoolInfoModalState {
  schoolInfo?
  showLoading?
  showInBetween?
  gradeOptions
  schoolOptions
  schoolOrChildCare?
  showAdditionalSchool?
  additionalSchoolInfo?
  additionalSchoolOrChildCare?
  additionalSchoolOptions?
  EducationSites
}

interface SchoolInfoModalProps {
  states?
  countries?
  gender?
  race?
  ethnicity?
  submit?
  t
  schools
  schoolDistricts
  childCareFacilities
  childDOB
  onClose?
}

class SchoolInfoModal extends Component<SchoolInfoModalProps, SchoolInfoModalState> {
  public static ID = "schoolinfo";

  constructor(props) {
    super(props)
    this.state = {
      showLoading: false,
      schoolOptions: [],
      EducationSites: [{ schoolOrChildCare: null, SchoolDistrict: null, School: null, ChildCareSite: null, SchoolGrade: null, SchoolPhone: null }],
      showAdditionalSchool: false,
      gradeOptions: [
        { label: "Headstart", value: "Headstart" },
        { label: "Pre-K", value: "Pre-K" },
        { label: "Kindergarten", value: "Kindergarten" },
        { label: "1st", value: "1st" },
        { label: "2nd", value: "2nd" },
        { label: "3rd", value: "3rd" },
        { label: "4th", value: "4th" },
        { label: "5th", value: "5th" },
        { label: "6th", value: "6th" },
        { label: "7th", value: "7th" },
        { label: "8th", value: "8th" },
        { label: "9th", value: "9th" },
        { label: "10th", value: "10th" },
        { label: "11th", value: "11th" },
        { label: "12th", value: "12th" }
      ]
    }
  }

  componentDidMount(): void {
    $(`#${SchoolInfoModal.ID}`).on('shown.bs.modal', function () {
      $(document).off('focusin.modal');
    });
  }

  validateSchool(schoolInfo) {
    let patientFormValidation = {
      SchoolOrChildCare: schoolInfo?.schoolOrChildCare,
      ChildCareSite: schoolInfo?.ChildCareSite,
      School: schoolInfo?.School,
      District: schoolInfo?.SchoolDistrict,
      Grade: schoolInfo?.SchoolGrade,
      Phone: schoolInfo?.SchoolPhone,
      OtherSchool: schoolInfo?.OtherSchool,
      OtherSchoolDistrict: schoolInfo?.OtherSchoolDistrict,
      OtherChildCareSite: schoolInfo?.OtherChildCareSite
    };
    if (!this.props.childDOB) {
      return { success: false, error: this.props.t("Please complete the Child Info section before completing the School Info section.") }
    }
    if (patientFormValidation.SchoolOrChildCare !== 0 && patientFormValidation.SchoolOrChildCare !== 1) {
      return { success: false, error: "School/Child Care cannot be empty." }
    }

    let validator = new Validator<any>()
    if (schoolInfo.schoolOrChildCare === 0) {
      validator = validator.withSimpleValidation("Grade", Validators.requireNonNullValidator())
        .withSimpleValidation("School", Validators.requireNonNullValidator())
        .withSimpleValidation("District", Validators.requireNonNullValidator())
        if(schoolInfo.School == -1){
          validator = validator.withSimpleValidation("OtherSchool", Validators.requireNonNullValidator("Other School"))
        }
        if(schoolInfo.SchoolDistrict == -1){
          validator = validator.withSimpleValidation("OtherSchoolDistrict", Validators.requireNonNullValidator("Other School District"))
        }
    } else if (schoolInfo.schoolOrChildCare === 1) {
      validator = validator.withSimpleValidation("ChildCareSite", Validators.requireNonNullValidator("Child Care Site"))
      if(schoolInfo.ChildCareSite == -1){
        validator = validator.withSimpleValidation("OtherChildCareSite", Validators.requireNonNullValidator("Other Child Care Site"))
      }
    }

    let validationResponse = validator.validate(patientFormValidation);
    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }
  }

  handleAddSchool() {
    this.setState({
      EducationSites: [
        ...this.state.EducationSites,
        { schoolOrChildCare: null, SchoolDistrict: null, School: null, ChildCareSite: null, SchoolGrade: null, SchoolPhone: null }
      ]
    });
  }

  handleRemoveSchool(index) {
    let schoolArray = this.state.EducationSites;
    let editedSchoolArray = schoolArray.splice(index, 1);
    this.setState({ EducationSites: schoolArray })
  }

  handleUpdateSchool(index, key, value) {
    let schoolArray = this.state.EducationSites
    schoolArray[index][key] = value;

    if (!this.state.EducationSites[index].SchoolDistrict && key === "School") {
      schoolArray[index].SchoolDistrict = this.props.schools.find((s) => s.ID === value).SchoolDistrictID
    }
    if (key === "SchoolDistrict" && (value !== -1 && this.state.EducationSites[index].School !== -1)) {
      schoolArray[index].School = null
    }
    this.setState((prevState) => ({
      EducationSites: schoolArray,
    }))
  }

  handleSave() {
    this.setState({ showLoading: true })

    let tempDate = this.state.schoolInfo ? this.state.schoolInfo.SchoolDOB : null;

    //validate patient info
    let res;

    for (let i = 0; i < this.state.EducationSites.length; i++) {
      let schools = JSON.parse(JSON.stringify(this.state.EducationSites))
      let school = schools[i]



      res = this.validateSchool(school)
      if (!res.success) {
        this.setState({ showLoading: false })
        return sweetalert.fire({
          icon: "error",
          title: "",
          text: this.props.t(res.error),
        });
      }

      school.SchoolGrade = school.SchoolGrade ? school.SchoolGrade.trim() : null
      school.SchoolPhone = school.SchoolPhone ? school.SchoolPhone.trim() : null

      schools[i] = school
      this.setState({ EducationSites: schools })
    }

    if (res.success) {
      //pass back to parent
      this.props.submit(this.state.EducationSites)

      hideModal(SchoolInfoModal.ID)
      this.setState({ showLoading: false })
    }


  }
  render() {

    let translationGradeOptions = this.state.gradeOptions && this.state.gradeOptions.length > 0 ? this.state.gradeOptions.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
    let translationSchoolOther = this.props.t("Type in the name of the school or search for the school through the drop-down list.  If you do not see your School or District listed, please select 'Other'.")
    let translationChildCareOther = this.props.t("Type in the name of the child care facility or search for the child care facility through the drop-down list.  If you do not see your child care facility listed, please select 'Other'.")
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} zIndex={100005} />
        <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
        <div className="modal fade form_modal" id={SchoolInfoModal.ID} tabIndex={-1} role="dialog"
          aria-hidden="true">
          <div className="modal-dialog modal-lg modal-xl col-8 px-0" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>
                                {this.props.t("School/ Child Care Information")}
                              </h4>
                            </div>
                            <div className="col-2">
                              <button style={{ outline: 'none' }} type="button" className="close"
                                aria-label={this.props.t("Close")} onClick={() => { hideModal(SchoolInfoModal.ID); this.props.onClose() }}>
                                <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>&times;</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {this.state.EducationSites.map((site, index) =>
                            <section key={`site${index + 1}`} className='border rounded py-2 px-4 mb-3' style={{ borderColor: "rgba(0, 0, 0, 0.125)" }}>
                              {this.state.EducationSites.length > 1 ?
                                <div className="row justify-content-end">
                                  <div className="d-flex justify-content-center align-content-center">
                                    <button style={{ outline: 'none' }} type="button" className="close"
                                      aria-label="Remove School/ Child Care" onClick={() => this.handleRemoveSchool(index)}>
                                      <span aria-hidden="true" className='d-inline-block pb-3 mr-3' style={{ fontSize: '1.5em', fontWeight: 'bold', color: "red" }}>&times;</span>
                                    </button>
                                  </div>
                                </div> : <></>}
                              {buildRow(this.props.t('School/ Child Care'),
                                <Select
                                  key={'School/Childcare'}
                                  id={'School/Childcare'}
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={this.props.t("Please Select...")}
                                  noOptionsMessage={() => this.props.t("No option")}
                                  aria-label={getReactSelectAriaLabel("School/ Child Care", getLabel(this.state.EducationSites[index].schoolOrChildCare, [{ label: "School", value: 0 }, { label: "Child Care", value: 1 }], this.props.t), this.props.t)}
                                  value={getLabel(this.state.EducationSites[index].schoolOrChildCare, [{ label: "School", value: 0 }, { label: "Child Care", value: 1 }], this.props.t)}
                                  onChange={(e) => {
                                    let schoolArray = this.state.EducationSites
                                    schoolArray[index] = { schoolOrChildCare: e?.value, SchoolDistrict: null, School: null, ChildCareSite: null, SchoolGrade: null, SchoolPhone: null }
                                    // this.handleUpdateSchool(index, "schoolOrChildCare", e)
                                    this.setState((prevState) => ({
                                      EducationSites: schoolArray,
                                    }))
                                  }}
                                  className={'state_select'}
                                  options={[{ label: this.props.t("School"), value: 0 }, { label: this.props.t("Child Care"), value: 1 }]}
                                />, this.props.t('School/ Child Care'), true
                              )}
                              {this.state.EducationSites[index].schoolOrChildCare === 0 ? <>
                                <p className='text-center'>{translationSchoolOther}</p>
                                {buildRow(this.props.t('Name of School'),
                                  <>
                                    <Select
                                      key={'SchoolName'}
                                      id={'SchoolName'}
                                      isClearable={true}
                                      isSearchable={true}
                                      filterOption={createFilter({ ignoreAccents: false })}
                                      placeholder={this.props.t("Please Select...")}
                                      noOptionsMessage={() => this.props.t("No option")}
                                      aria-label={getReactSelectAriaLabel("Name of School", this.state.EducationSites[index].School ? getLabel(this.state.EducationSites[index].School, this.props.schools.map((school) => { return { label: school.SchoolName, value: school.ID } })) : null, this.props.t)}
                                      value={this.state.EducationSites[index].School ? getLabel(this.state.EducationSites[index].School, this.props.schools.map((school) => { return { label: school.SchoolName, value: school.ID } })) : null}
                                      onChange={(e) => {
                                        this.handleUpdateSchool(index, "School", e?.value)
                                        //   this.setState((prevState) => ({
                                        //     schoolInfo: {
                                        //         ...prevState.schoolInfo,
                                        //         School: e.value
                                        //     }
                                        // }))
                                      }}
                                      className={'state_select'}
                                      options={this.state.EducationSites[index].SchoolDistrict ? this.props.schools.filter((s) => s.SchoolDistrictID === this.state.EducationSites[index].SchoolDistrict).map((school) => { return { label: school.SchoolName, value: school.ID } }) : this.props.schools.map((school) => { return { label: school.SchoolName, value: school.ID } })}
                                    />

                                  </>
                                  , this.props.t('Name Of School'), true
                                )}
                                {this.state.EducationSites[index].School == -1 ? buildRow(this.props.t('Other Name of School'),
                                  <input
                                    id={'SchoolName'}
                                    className={"form-control"}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={'SchoolName'}
                                    aria-label={this.props.t("Name of School")}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "OtherSchool", e?.target?.value)
                                      //   this.setState((prevState) => ({
                                      //     schoolInfo: {
                                      //         ...prevState.schoolInfo,
                                      //         School: e.value
                                      //     }
                                      // }))
                                    }}
                                    value={this.state.EducationSites[index].OtherSchool}
                                  />, this.props.t('Name of School'), true
                                ): <></>}
                                {buildRow(this.props.t('School District'),
                                  <Select
                                    key={'SchoolDistrict'}
                                    id={'SchoolDistrict'}
                                    isClearable={true}
                                    isSearchable={true}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    placeholder={this.props.t("Please Select...")}
                                    noOptionsMessage={() => this.props.t("No option")}
                                    aria-label={getReactSelectAriaLabel("School District", this.state.EducationSites[index]?.SchoolDistrict ? getLabel(this.state.EducationSites[index]?.SchoolDistrict, this.props.schoolDistricts) : null, this.props.t)}
                                    value={this.state.EducationSites[index]?.SchoolDistrict ? getLabel(this.state.EducationSites[index]?.SchoolDistrict, this.props.schoolDistricts) : null}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "SchoolDistrict", e?.value)
                                    }}
                                    className={'state_select'}
                                    options={this.props.schoolDistricts}
                                  />, this.props.t('School District'), true
                                )}
                                {this.state.EducationSites[index].SchoolDistrict == -1 ? buildRow(this.props.t('Other School District'),
                                  <input
                                    id={'SchoolDistrict'}
                                    className={"form-control"}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={'SchoolDistrict'}
                                    aria-label={this.props.t("School District")}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "OtherSchoolDistrict", e?.target?.value)
                                      //   this.setState((prevState) => ({
                                      //     schoolInfo: {
                                      //         ...prevState.schoolInfo,
                                      //         School: e.value
                                      //     }
                                      // }))
                                    }}
                                    value={this.state.EducationSites[index].OtherSchoolDistrict}
                                  />, this.props.t('School District'), true
                                ): <></>}
                              </> : this.state.EducationSites[index].schoolOrChildCare === 1 ?
                                <>
                                  <p className='text-center'>{translationChildCareOther}</p>
                                  {buildRow(this.props.t('Name of Child Care'),
                                    <Select
                                      key={'ChildCareSite'}
                                      id={'ChildCareSite'}
                                      isSearchable={true}
                                      isClearable={true}
                                      filterOption={createFilter({ ignoreAccents: false })}
                                      placeholder={this.props.t("Please Select...")}
                                      noOptionsMessage={() => this.props.t("No option")}
                                      aria-label={getReactSelectAriaLabel("Name of Child Care", this.state.EducationSites[index].ChildCareSite ? getLabel(this.state.EducationSites[index].ChildCareSite, this.props.childCareFacilities) : null, this.props.t)}
                                      value={this.state.EducationSites[index].ChildCareSite ? getLabel(this.state.EducationSites[index].ChildCareSite, this.props.childCareFacilities.map((f) => {return {label: f.ChildCareSiteName, value: f.ID}})) : null}
                                      onChange={(e) => {
                                        this.handleUpdateSchool(index, "ChildCareSite", e?.value)
                                        //   this.setState((prevState) => ({
                                        //     schoolInfo: {
                                        //         ...prevState.schoolInfo,
                                        //         ChildCareSite: e.value
                                        //     }
                                        // }))
                                      }}
                                      className={'state_select'}
                                      options={this.props.childCareFacilities.map((facility) => { return { label: facility.ChildCareSiteName, value: facility.ID } })}
                                    />, this.props.t('Name of Child Care'), true
                                  )}
                                   {this.state.EducationSites[index].ChildCareSite == -1 ? buildRow(this.props.t('Other Name of Child Care'),
                                  <input
                                    id={'ChildCareSite'}
                                    className={"form-control"}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={'ChildCareSite'}
                                    aria-label={this.props.t("Child Care Site")}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "OtherChildCareSite", e?.target?.value)
                                      //   this.setState((prevState) => ({
                                      //     schoolInfo: {
                                      //         ...prevState.schoolInfo,
                                      //         School: e.value
                                      //     }
                                      // }))
                                    }}
                                    value={this.state.EducationSites[index].OtherSchoolDistrict}
                                  />, this.props.t('School District'), true
                                ): <></>}
                                  </> : <></>
                              }
                              {this.state.EducationSites[index].schoolOrChildCare === 0 && buildRow(this.props.t('Grade'),
                                <Select
                                  key={'SchoolGrade'}
                                  id={'SchoolGrade'}
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={this.props.t("Please Select...")}
                                  noOptionsMessage={() => this.props.t("No option")}
                                  aria-label={getReactSelectAriaLabel("Grade", this.state.EducationSites[index].SchoolGrade ? getLabel(this.state.EducationSites[index].SchoolGrade, this.state.gradeOptions, this.props.t) : null, this.props.t)}
                                  value={this.state.EducationSites[index].SchoolGrade ? getLabel(this.state.EducationSites[index].SchoolGrade, this.state.gradeOptions, this.props.t) : null}
                                  onChange={(e) => {
                                    this.handleUpdateSchool(index, "SchoolGrade", e?.value)
                                  }}
                                  className={'state_select'}
                                  options={translationGradeOptions}
                                />, this.props.t('Grade'), true
                              )}
                              {this.state.EducationSites[index].schoolOrChildCare === 0 ?
                                buildRow(this.props.t('School Phone'),
                                  <PhoneInput
                                    id={'SchoolPhone'}
                                    placeholder={this.props.t("Enter phone number (10 digit)")}
                                    onChange={(e) =>
                                      //   this.setState( (prevState) => ({
                                      //   schoolInfo: {
                                      //       ...prevState.schoolInfo,
                                      //       SchoolPhone: e
                                      //   }
                                      // }))
                                      this.handleUpdateSchool(index, "SchoolPhone", e)
                                    }
                                    defaultCountry="US"
                                    aria-label={this.props.t('School Phone')}
                                    value={this.state.EducationSites[index].SchoolPhone} />, this.props.t('School Phone')
                                ) : this.state.EducationSites[index].schoolOrChildCare === 1 ?
                                  buildRow(this.props.t('Child Care Phone'),
                                    <PhoneInput
                                      id={'ChildcarePhone'}
                                      placeholder={this.props.t("Enter phone number (10 digit)")}
                                      onChange={(e) =>
                                        //   this.setState( (prevState) => ({
                                        //   schoolInfo: {
                                        //       ...prevState.schoolInfo,
                                        //       SchoolPhone: e
                                        //   }
                                        // }))
                                        this.handleUpdateSchool(index, "SchoolPhone", e)
                                      }
                                      defaultCountry="US"
                                      aria-label={this.props.t('Child Care Phone')}
                                      value={this.state.EducationSites[index].SchoolPhone} />, this.props.t('Child Care Phone')
                                  ) : <></>
                              }</section>)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                    <p className='mx-3 d-sm-none d-block text-center'><section className="text-danger d-inline-block px-1">*</section> Required Fields</p>
                    <button className={"btn btn-success"} onClick={() => this.handleAddSchool()}>{this.props.t("Add Additional")}</button>
                    <p className='mx-3 d-none d-sm-inline-block'><section className="text-danger d-inline-block px-1">*</section>{this.props.t("Required Fields")}</p>
                    <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>{this.props.t('Save')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(SchoolInfoModal);
